import AwesomePhoneNumber from 'awesome-phonenumber';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { actions, createMachine } from 'xstate';
import { useMachine } from '@xstate/react';

const { assign, choose, send } = actions;

export default function useOfferState(initialContext) {
  const { formatMessage } = useIntl();
  const [state, send] = useMachine(machine, {
    context: initialContext,
    actions: {
      handleFormErrors: assign((context, event) => {
        const { data: error } = event;
        const newContext = { ...context };
        let message = formatMessage({
          defaultMessage: 'Something went wrong.',
          id: 'ERR_GENERIC',
        });
        if (error.response && error.response.data) {
          const errorKeys = error.response.data.split(',');
          if (errorKeys.includes('ERR_INCORRECT_CODE')) {
            newContext.verificationAttemptsRemaining = Math.max(
              0,
              newContext.verificationAttemptsRemaining - 1,
            );
          }
          if (
            errorKeys.includes('ERR_MAX_CHECK_ATTEMPTS') ||
            errorKeys.includes('ERR_TOO_MANY_REQUESTS')
          ) {
            newContext.verificationAttemptsRemaining = 0;
          }
          if (errorKeys.length === 1) {
            message = formatMessage({ id: errorKeys[0] }, newContext);
          } else {
            message =
              formatMessage(
                {
                  defaultMessage: 'Please fix the following issues:',
                  id: 'ERR_FORM_INVALID',
                },
                newContext,
              ) + '\n';
            message += errorKeys
              .map(id => formatMessage({ id }, newContext))
              .join('\n');
          }
        }
        window.alert(message);
        return newContext;
      }),
    },
    devTools: process.env.NODE_ENV === 'development',
  });
  return [state, send];
}

const machine = createMachine(
  {
    id: 'OfferForm',
    context: {
      verificationAttemptsRemaining: 5,
      busy: false,
      code: '',
      config: null,
      country: null,
      domain: '',
      email: '',
      name: '',
      offer: '',
      phone: '',
      phoneIsValid: null,
    },
    on: {
      SET_PAGE_DATA: {
        actions: assign((context, event) => ({
          config: event.config,
          domain: event.domain,
        })),
      },
    },
    initial: 'initial',
    states: {
      initial: {
        initial: 'initial',
        on: {
          CONTINUE_ANYWAY: 'details',
          INPUT_OFFER: {
            actions: 'setOffer',
          },
          NEXT: [
            {
              target: '.tooLowOffer',
              cond: 'ltMinOffer',
            },
            {
              target: '.lowOffer',
              cond: 'ltSuggestedOffer',
            },
            'details',
          ],
          SELECT_SMS: '.showSMS',
        },
        states: {
          initial: {},
          tooLowOffer: {
            on: {
              BACK: 'initial',
            },
          },
          lowOffer: {
            on: {
              BACK: 'initial',
            },
          },
          showSMS: {},
        },
      },
      details: {
        initial: 'initial',
        on: {
          BACK: 'initial',
          INPUT_EMAIL: {
            actions: 'setEmail',
          },
          INPUT_NAME: {
            actions: 'setName',
          },
          INPUT_OFFER: {
            actions: 'setOffer',
          },
          NEXT: [
            {
              target: '.tooLowOffer',
              cond: 'ltMinOffer',
            },
            'phone',
          ],
        },
        states: {
          initial: {},
          tooLowOffer: {
            on: {
              BACK: 'initial',
            },
          },
        },
      },
      phone: {
        initial: 'enterNumber',
        on: {
          BACK: 'details',
          INPUT_CODE: {
            actions: 'setCode',
          },
          INPUT_COUNTRY: {
            actions: 'setCountry',
          },
          INPUT_PHONE: {
            actions: 'setPhone',
          },
          'done.invoke.submitOffer': 'thanks',
        },
        states: {
          enterNumber: {
            on: {
              NEXT: [
                {
                  target: '.submitOffer',
                  cond: 'skipCountryCodes',
                },
                '.sendingCode',
              ],
              SUBMIT: '.submitOffer',
              'done.invoke.sendCode': {
                actions: assign((_context, event) => {
                  if (!event.data) return;
                  const pn = AwesomePhoneNumber(event.data);
                  return {
                    phoneFormatIntl: pn.getNumber('international'),
                    phoneUserInput: pn.getNumber('national'),
                  };
                }),
                target: 'confirmCode',
              },
            },
            initial: 'initial',
            states: {
              initial: {},
              submitOffer: {
                entry: 'setBusyTrue',
                exit: 'setBusyFalse',
                invoke: {
                  src: 'submitOffer',
                  onError: {
                    target: 'initial',
                    actions: 'handleFormErrors',
                  },
                },
              },
              sendingCode: {
                entry: 'setBusyTrue',
                exit: 'setBusyFalse',
                invoke: {
                  src: 'sendCode',
                  onError: {
                    target: 'initial',
                    actions: choose([
                      {
                        cond: 'skipVerify',
                        actions: send('SUBMIT'),
                      },
                      {
                        actions: 'handleFormErrors',
                      },
                    ]),
                  },
                },
              },
            },
          },
          confirmCode: {
            initial: 'initial',
            on: {
              BACK: 'enterNumber',
              INPUT_CODE: {
                actions: 'setCode',
              },
              NEXT: '.submitOffer',
            },
            states: {
              initial: {},
              submitOffer: {
                entry: 'setBusyTrue',
                exit: 'setBusyFalse',
                invoke: {
                  src: 'submitOffer',
                  onError: {
                    target: 'initial',
                    actions: 'handleFormErrors',
                  },
                },
              },
            },
          },
        },
      },
      thanks: {
        type: 'final',
      },
    },
  },
  {
    actions: {
      setBusyTrue: assign(() => ({
        busy: true,
      })),
      setBusyFalse: assign(() => ({
        busy: false,
      })),
      setCode: assign((_context, event) => ({
        code: event.value,
      })),
      setCountry: assign((_context, event) => ({
        country: event.value,
      })),
      setEmail: assign((_context, event) => ({
        email: event.value,
      })),
      setName: assign((_context, event) => ({
        name: event.value,
      })),
      setOffer: assign((_context, event) => ({
        offer: window.parseInt(event.value.replace(/[^0-9]/g, ''), 10) || '',
      })),
      setPhone: assign((context, event) => {
        const { country } = context;
        const regionCode = AwesomePhoneNumber.getRegionCodeForCountryCode(
          country.phone,
        );
        const formatter = AwesomePhoneNumber.getAsYouType(regionCode);
        formatter.reset(event.value.replace(/[^0-9]/g, ''));
        const pn = formatter.getPhoneNumber();
        return {
          verificationAttemptsRemaining: 5,
          phoneFormatIntl: pn.getNumber('international'),
          phoneIsValid: formatter.getPhoneNumber().isValid(),
          phoneUserInput: formatter.number() || event.value,
        };
      }),
    },
    guards: {
      ltSuggestedOffer: context => {
        if (context.config.suggestedOffer) {
          return context.offer < context.config.suggestedOffer;
        }
        return false;
      },
      ltMinOffer: context => {
        if (context.config.minimumOffer) {
          return context.offer < context.config.minimumOffer;
        }
        return false;
      },
      skipCountryCodes: context => {
        // const skipCountryCodes = ['86', '63'];
        // return skipCountryCodes.includes(context.country.phone);
        return true;
      },
      skipVerify: (_context, event) => {
        return event.data.response.data === 'ERR_GENERIC';
      },
    },
    services: {
      sendCode: async context => {
        const { phoneFormatIntl } = context;
        if (phoneFormatIntl) {
          const response = await axios.post('/send-code.php', {
            phone: phoneFormatIntl,
          });
          return response.data;
        }
      },
      submitOffer: async context => {
        const { country, code, domain, email, name, offer, phoneFormatIntl } =
          context;
        await axios.post('/submit.php', {
          country: country.label,
          domain,
          code,
          email,
          name,
          offer,
          phone: phoneFormatIntl,
        });
      },
    },
  },
);
